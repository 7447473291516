import React from "react";
import { useForm, Controller } from 'react-hook-form';

import PhoneInput, { selectedCountry } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'


// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  Button,
  Col,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Form,
  FormGroup,
  FormText,
  Card,

} from "reactstrap";

function IndexNavbar() {
  const [modal1, setModal1] = React.useState(false);
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [CompanyFocus, setCompanyFocus] = React.useState(false);
  const [CommentsFocus, setCommentsFocus] = React.useState(false);
  const [PhoneNumberFocus, setPhonelNumberFocus] = React.useState(false);



  const { formState: { errors }, register, handleSubmit, reset, setError, control, setValue } = useForm(
    { mode: "onChange" });

  const onSubmit = (data) => {
    // console.log(data);
    fetch(
      'https://demo.doityourself.io:443/api/ContactUs'
      , {
        headers: { 'Content-Type': 'application/json' },
        method: 'POST',
        body: JSON.stringify(data),
      })
      .then(response => {
        console.log(response)
        if (response.status >= 400) {
          setError("Email")
        }
        else {
          reset();
          setModal1(false);
        }
      });
  }

  React.useEffect(() => {

    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="CustomNavBarColor">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand className="CustomNavBarBrand"
              href="https://doityourself.io"
              id="navbar-brand">
              doityourself.io
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button">
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar>
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="https://docs.doityourself.io/docs/getting-started/"
                  target="_blank">
                  <p className="CustomNavBar">LEARNING CENTER</p>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink
                  href="">
                  <p className="CustomNavBar">BLOG</p>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink href="#diy" onClick={() => setModal1(true)}  >
                  <p className="CustomNavBar">CONTACT US</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://demo.doityourself.io"
                  target="_blank">
                  <p className="CustomNavBar">SIGN IN</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  href="https://www.linkedin.com/company/doityourself/"
                  target="_blank"
                  id="linkedin-tooltip">
                  <i className="fab fa-linkedin"></i>
                  <p className="d-lg-none d-xl-none">LinkedIn</p>
                </NavLink>
                <UncontrolledTooltip target="#linkedin-tooltip">
                  Follow us on LinkedIn
                </UncontrolledTooltip>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
      <Modal isOpen={modal1} toggle={() => setModal1(false)}>
        <div className="modal-header justify-content-center">
          <button
            className="close"
            type="button"
            onClick={() => setModal1(false)}>
            <i className="now-ui-icons ui-1_simple-remove"></i>
          </button>
          <h4 className="title title-up"></h4>
        </div>
        <ModalBody>
          <div className="section section-contact-us text-center">
            <Container>
              <Card className="titleCard">
                <h3 className="title" >CONTACT US</h3>
              </Card>
              <Row>
                <Col className="text-center ml-auto mr-auto" lg="12" md="12">
                  <Form>
                    <FormGroup>
                      <InputGroup
                        onBlur={() => setFirstFocus(false)}
                        className={
                          "no-border" + (firstFocus ? " input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          type="text" className="form-control"
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          placeholder="First Name..."
                          {...register("firstName", {
                            validate: (value) => value.length >= 3,
                            required: true,
                            pattern: /^[a-zA-Z\-]*$/,
                          })}
                        />
                      </InputGroup>
                      {errors.firstName?.type === 'pattern' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>No Numbers, No Symbols</small>}
                      {errors.firstName?.type === 'required' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>First Name Required</small>}
                      {errors.firstName && <small className="customFromTextSubtext form-text " style={{ color: "tomato" }}> At least 3 characters</small>}
                      <FormText color={"muted"} className={"customFromTextSubtext"} >
                        We'll never share your Personal Information.
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        onBlur={() => setLastFocus(false)}
                        className={
                          "no-border" + (lastFocus ? " input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          type="text" className="form-control"
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                          placeholder="Last Name..."
                          {...register("lastName", {
                            validate: (value) => value.length >= 3,
                            required: true,
                            pattern: /^[a-zA-Z\-]*$/,
                          })}
                        />
                      </InputGroup>
                      {errors.lastName?.type === 'pattern' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>No Numbers, No Symbols</small>}
                      {errors.lastName?.type === 'required' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>Last Name Required</small>}
                      {errors.lastName && <small className="customFromTextSubtext form-text " style={{ color: "tomato" }}> At least 3 characters</small>}
                      <FormText color={"muted"} className={"customFromTextSubtext"} >
                        We'll never share your Personal Information.
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        onBlur={() => setEmailFocus(false)}
                        className={
                          "no-border" + (emailFocus ? " input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          className="form-control"
                          placeholder="Email..."
                          type="text"
                          onFocus={() => setEmailFocus(true)}
                          onBlur={() => setEmailFocus(false)}
                          {...register("Email", {
                            required: true,
                            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          })}
                        />
                      </InputGroup>
                      {errors.Email?.type === 'pattern' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>Enter Valid Email Format xxx@xxx.xxx</small>}
                      {errors.Email?.type === 'required' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>Please Enter Required Field</small>}
                      <FormText color={"muted"} className={"customFromTextSubtext"} ></FormText>
                      <FormText color={"muted"} className={"customFromTextSubtext"}>
                        We'll never share your Email Information.
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        onBlur={() => setCompanyFocus(false)}
                        className={
                          "no-border" + (CompanyFocus ? " input-group-focus" : "")}>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons objects_planet"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <input
                          className="form-control"
                          placeholder="Company & Industy"
                          type="text"
                          onFocus={() => setCompanyFocus(true)}
                          onBlur={() => setCompanyFocus(false)}
                          {...register("Company", {
                            validate: (value) => value.length >= 3,
                            required: true,
                            pattern: /^[a-zA-Z\-]*$/
                          })}
                        />
                      </InputGroup>
                      {errors.Company?.type === 'pattern' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>No Numbers, No Symbols</small>}
                      {errors.Company?.type === 'required' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>Please Enter Required Field</small>}
                      {errors.Company && <small className="customFromTextSubtext form-text " style={{ color: "tomato" }}> At least 3 characters</small>}
                      <FormText color={"muted"} className={"customFromTextSubtext"}>
                        Company / Industry
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        onBlur={() => setPhonelNumberFocus(false)}
                        className={
                          "no-border" + (PhoneNumberFocus ? " input-group-focus" : "")}>
                        <Controller
                          rules={{ required: true }}
                          name="phone"
                          control={control}
                          render={({ field: { onChange, value } }) => (
                            <PhoneInput
                              country={'fr'}
                              value={value}
                              countryCodeEditable={false}
                              onChange={(value, country) => {
                                onChange(value)
                                setValue("country", country.name)
                              }}
                            />
                          )}
                        />
                        <input hidden defaultValue={"France"} {...register('country')}></input>
                      </InputGroup>
                      {errors.phone?.type === 'required' && <small className="invalid customFromTextSubtext form-text Danger" style={{ color: "tomato" }}>Please Enter Required Field</small>}
                      <FormText color={"muted"} className={"customFromTextSubtext"}>
                        Enter Phone Number
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <InputGroup
                        onBlur={() => setCommentsFocus(false)}
                        className={
                          "no-border" + (CommentsFocus ? " input-group-focus" : "")}>
                        <textarea
                          rows="4"
                          cols="80"
                          className="form-control"
                          placeholder="Explain Us Your Needs..."
                          type="textarea"
                          onFocus={() => setCommentsFocus(true)}
                          onBlur={() => setCommentsFocus(false)}
                          {...register("Comments")}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="send-button">
                      <Button
                        block
                        name="sub"
                        className="btn-round SubmitButton"
                        color="info"
                        onClick={handleSubmit(onSubmit)}
                        size="lg"
                      >
                        GET STARTED
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}

export default IndexNavbar;
