import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClipboardList, faServer, faRandom, faBrowser, faCogs, faReply } from '@fortawesome/pro-duotone-svg-icons'
import { faCloud as faCloudEmpty } from '@fortawesome/pro-light-svg-icons'

function DIYPower() {
  return (
    <>
      <div className="section section-team text-center customBackgroundPDIY">
        <Container fluid="md" >
          <h2 className="title title-up customTitleDIY" >the power of diy</h2>
          <h5 className="title customSubTitleDIY">
            <strong>DIY</strong> allows <strong>Frontend developers </strong>
            to easily work with various <strong>Databases</strong>,{<br />} <strong>Backend developers </strong> to save
            weeks of hand-coding <strong>API{"'"}s</strong>, in a <strong>Secure Dedicated Cloud </strong> Environment.
          </h5>
          <div className="team ">
            <Row className="PwDIYcontainer">
              <Col className="ColPadPwr">
                <div className="team-player">
                  <div className="fa-layers fa-fw section-icon">
                    <FontAwesomeIcon color="#00acc1" icon={faCogs} size={"3x"} transform={"left-1"} />
                    <FontAwesomeIcon className={"fa-flip-both"} color="#00acc1" icon={faReply} size={"2x"} transform={"down-13  left-15"} />
                  </div>
                  <h5 className="title sectionIconTitle"><span>Business{<br />}Automation</span></h5>
                  <p className="description sectionIconDesc">
                    <span><strong>Digitize your business with API{"'"}s</strong> that combines
                      business processes into reusable services that <strong>scale across
                        channels </strong>to deliver connected and consistent customer,
                      partner, and employee experiences.</span>
                  </p>
                </div>
              </Col>
              <Col className="ColPadPwr">
                <div className="team-player">
                  <div className="fa-layers fa-fw section-icon">
                    <FontAwesomeIcon className={""} color="green" icon={faClipboardList} size={"4x"} transform={"left-1"} />
                  </div>
                  <h5 className="title sectionIconTitle"><span>Legacy System{<br />}Modernization</span></h5>
                  <p className="description sectionIconDesc">
                    <span>
                      <strong>DIY</strong> allow's you to leverage API{"'"}s
                      to <strong>decouple legacy system</strong> complexity from their
                      services and data. This <strong>reduces maintenance costs</strong>, while
                      ensuring access to your systems of record.</span>
                  </p>
                </div>
              </Col>
              <Col className="ColPadPwr">
                <div className="team-player">
                  <div className="fa-layers fa-fw section-icon">
                    <FontAwesomeIcon color="gray" icon={faCloudEmpty} size={"4x"} transform={""} />
                    <FontAwesomeIcon color="gray" icon={faServer} size={"2x"} transform={"down-2"} />
                  </div>
                  <h5 className="title sectionIconTitle"><span>On Premise {<br />} Or Cloud</span></h5>
                  <p className="description sectionIconDesc">
                    <span><strong>DIY</strong> suits both needs, offering
                      an <strong>on-premise</strong>  so your data never leaves your walls,
                      and a <strong> cloud-based hosted</strong>  solution for businesses that
                      desire a <strong>World{"-"}Class Secure API Services Solution </strong>
                      without the administrative overhead.</span>
                  </p>
                </div>
              </Col>
              <Col className="ColPadPwr">
                <div className="team-player">
                  <div className="fa-layers fa-fw section-icon">
                    <FontAwesomeIcon color="orange" icon={faRandom} size={"4x"} transform={""} />
                  </div>
                  <h5 className="title sectionIconTitle">DIY {<br />}Data Flow</h5>
                  <p className="description sectionIconDesc">
                    <span><strong>Digitize your business with API{"'"}s</strong> that combines
                      business processes into reusable services that <strong>scale across
                        channels </strong>to deliver connected and consistent customer,
                      partner, and employee experiences.</span>
                  </p>
                </div>
              </Col>
              <Col className="ColPadPwr">
                <div className="team-player">
                  <div className="fa-layers fa-fw section-icon">
                    <FontAwesomeIcon color="tomato" icon={faBrowser} size={"4x"} transform={""} />
                  </div>
                  <h5 className="title sectionIconTitle">Application{<br />}Development</h5>
                  <p className="description sectionIconDesc">
                    <span><strong>DIY</strong> offers
                      <strong> NoSQL Database</strong> backend for
                      <strong> Web and Serverless Aspplications.
                      </strong> Model your information quickly,
                      <strong> Schema and REST API are
                        instantly available.</strong></span>
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

export default DIYPower;
