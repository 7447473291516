import React from "react";
import LinkedInPost from "components/Blog/LinkedPost";
// reactstrap components
import {
  Container,

} from "reactstrap";


function Blog() {
  return (
    <>
      <div className="section  ">
        <Container fluid >
          <h2 className="title title-up customTitleDIY" >DIY RESOURCES</h2>
          <div className="LinkedInPostContainerScroll" >
            <div className="LinkedInPostContainerStyle">
            <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7043591755992059904"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7041417165656199169"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7041004093338738688"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7036339459033849858"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7034459738926915584"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7034459519053111296"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7029451928547782656"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7029451224433221632"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:7026532899948515328"
                />
              </div>
              <div className="LinkedInPostStyle" >
                <LinkedInPost
                  url="https://www.linkedin.com/embed/feed/update/urn:li:share:6917401350402158592"
                />
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default Blog;


