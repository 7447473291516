/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Col, Row } from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/pro-duotone-svg-icons'

function DarkFooter() {
  return (
    <footer className="copyRightFooter" data-background-color="black">
        <Row className="align-items-center">
          <Col md={{
            offset: 3,
            size: 3
            }}><img
              alt="..."
              className="diy-logoFooter"
              src={require("assets/img/logo_50.png").default}
            ></img>
          </Col>
          <Col className=""
            md={{
              offset: 1,
              size: 3
            }}>
            <div className=""> © {new Date().getFullYear()}, Made with 
              <FontAwesomeIcon color="tomato" icon={faHeart} size={"1x"} style={{padding:"0 4px 0 4px"}}/>
              by <a href="" style={{color:"orange"}}>DoItYourself.io</a> for a better Web</div>
             
          </Col>
        </Row>
    </footer>
  );
}

export default DarkFooter;

