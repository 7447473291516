import React from "react";

// reactstrap components
import {
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    Container,
    Row,
    Col,
} from "reactstrap";

// core components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBracketsCurly, faServer, faUserSecret, faBrowser, faRandom, faSignalStream } from '@fortawesome/pro-duotone-svg-icons'

function Tabs() {
    const [iconPills, setIconPills] = React.useState("1");
    return (
        <>
            <div className="section ">
                <Container fluid>
                    <h2 className="title title-up customTitleDIY " >How Can you DIY ?</h2>
                    <Row>
                        <Col>
                            <Nav className="justify-content-center" role="tablist" pills>
                                <div className="pillsRoot">
                                    <NavItem className="pillsCustom">
                                        <NavLink
                                            className={iconPills === "1" ? "active" : ""}
                                            href="#diy"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIconPills("1");
                                            }}>
                                            <FontAwesomeIcon color="#00acc1" icon={faBracketsCurly} size={"3x"} />
                                            {<br />}Easy API Definition
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="pillsCustom">
                                        <NavLink
                                            className={iconPills === "2" ? "active" : ""}
                                            href="#diy"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIconPills("2");
                                            }}>
                                            <FontAwesomeIcon color="#00acc1" icon={faUserSecret} size={"3x"} />
                                            {<br />}SECURITY
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="pillsCustom">
                                        <NavLink
                                            className={iconPills === "3" ? "active" : ""}
                                            href="#diy"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIconPills("3");
                                            }}>
                                            <FontAwesomeIcon color="gray" icon={faServer} size={"3x"} />
                                            {<br />}Data model hosting
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="pillsCustom">
                                        <NavLink
                                            className={iconPills === "4" ? "active" : ""}
                                            href="#diy"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIconPills("4");
                                            }}>
                                            {/* <i className="now-ui-icons ui-2_settings-90"></i> */}
                                            <span className="FaIcnColrActive">
                                                <FontAwesomeIcon color="gray" icon={faBrowser} size={"3x"} />
                                            </span>
                                            {<br />}Integration
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="pillsCustom">
                                        <NavLink
                                            className={iconPills === "5" ? "active" : ""}
                                            href="#diy"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIconPills("5");
                                            }}>
                                            {/* <i className="now-ui-icons ui-2_settings-90"></i> */}
                                            <span className="FaIcnColrActive">
                                                <FontAwesomeIcon color="orange" icon={faRandom} size={"3x"} />
                                            </span>
                                            {<br />}Realtime data manipulation
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="pillsCustom">
                                        <NavLink
                                            className={iconPills === "6" ? "active" : ""}
                                            href="#diy"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setIconPills("6");
                                            }}>
                                            {/* <i className="now-ui-icons ui-2_settings-90"></i> */}
                                            <span className="FaIcnColrActive">
                                                <FontAwesomeIcon color="orange" icon={faSignalStream} size={"3x"} />
                                            </span>
                                            {<br />}API Publishing
                                        </NavLink>
                                    </NavItem>
                                </div>
                            </Nav>
                            <Container  >
                                <TabContent
                                    className="customCard"
                                    activeTab={"iconPills" + iconPills}>
                                    <TabPane tabId="iconPills1" >
                                        <span>
                                            <h5>
                                                DIY enables API design directly within the cloud, making
                                                API creation better and faster.
                                            </h5>
                                            <h4>
                                                With the API feature in DIY, your schema is now treated
                                                as the source which defines every variation of the API.
                                                Collections can now be used as individual recipes which
                                                use endpoints made available by the API, tailored to
                                                specific use cases. When you map your real-world APIs,
                                                you will be able to define, develop, test, and observe
                                                them directly within DIY.
                                            </h4>
                                            <ul>
                                                <li>
                                                    <h5>
                                                        Design and develop APIs in your workspace, keep them
                                                        consistent and backward-compatible
                                                    </h5>
                                                </li>
                                                <li>
                                                    <h5>Connect Database's into API's Endpoints</h5>
                                                </li>
                                                <li><h5>Convert SOAP into REST</h5></li>
                                                <li><h5>Merge Multiple Database</h5></li>
                                                <li><h5>Share APIs with your teams and members</h5></li>
                                                <li><h5>Streamline the entire API workflow around the API itself</h5></li>
                                                <li>
                                                    <h5>
                                                        Iterate on your API enhancements in a much more
                                                        dynamic, secure, and organized way
                                                    </h5>
                                                </li>
                                            </ul>
                                        </span>
                                    </TabPane>
                                    <TabPane tabId="iconPills2">
                                        <span>
                                            <h5>DIY offers a REST API platform that helps secure data access.
                                                Especially now that a growing number of web and mobile apps need to
                                                access sensitive data, and proper controls are imperative in the services
                                                used to access that data from an expanding number of apps.</h5>
                                            <h5>Security features include:</h5>
                                            <ul>
                                                <li><h5>Dedicated Cloud Environment, with white URL label Access (Single Tenant)</h5></li>
                                                <li><h5>Single sign on, user management, user roles and permissions, and secure password hashing.</h5></li>
                                                <li><h5>Flexible access control over applications, services, files, and database CRUD permissions.</h5></li>
                                                <li><h5>Tokenization Service</h5></li>
                                                <li><h5>SSL and x.509 encrypt and authenticate every transaction</h5></li>
                                                <li><h5>HTTPS communication with encrypted credentials</h5></li>
                                                <li><h5>DIY does not store transactional data</h5></li>
                                                <li><h5>Meets SOC1, SOC2, HIPAA, and GDPR compliance</h5></li>
                                                <li><h5>Team and user-based access permissions and delegation</h5></li>

                                            </ul>

                                        </span>
                                    </TabPane>
                                    <TabPane tabId="iconPills3">
                                        <span>
                                            <h5>
                                                DIY Offers an NoSQL database backend for web and
                                                serverless "low code" applications.
                                                <br></br>Schema and REST API are instantly available
                                                using our Automation technology. The online database
                                                tool is designed for the modern web with high
                                                performance automated REST API, low code server-side
                                                javascript codehooks, user authentication (Auth0)
                                            </h5>
                                            <ul>
                                                <li>
                                                    <h5>Model your information quickly</h5>
                                                </li>
                                                <li>
                                                    <h5>Built for the modern Web</h5>
                                                </li>
                                                <li>
                                                    <h5>Define Data Types and Relations</h5>
                                                </li>
                                                <li>
                                                    <h5>Role-based Access Control</h5>
                                                </li>
                                            </ul>
                                        </span>
                                    </TabPane>
                                    <TabPane tabId="iconPills4">
                                        <span>
                                            <h5>Connect apps and data with speed and ease.</h5>
                                            <h5>
                                                Turn IT complexity into connectivity. Break down data
                                                silos by integrating on-premise and cloud applications,
                                                data sources and devices across your distributed
                                                environment.
                                            </h5>
                                        </span>
                                    </TabPane>
                                    <TabPane tabId="iconPills5">
                                        <span>
                                            <h5>
                                                DIY Flow is a programming tool for wiring together
                                                hardware devices, APIs and online services in new and
                                                interesting ways.
                                            </h5>
                                            <h5>
                                                It provides a browser-based editor that makes it easy to
                                                wire together flows using the wide range of nodes that
                                                can be deployed to its runtime in a single-click.
                                            </h5>
                                            <h5>
                                                Enable anyone from IT generalists to process analysts to
                                                easily create end-to-end digital workflows that automate
                                                any business process—from simple productivity to complex
                                                transformation in NoCode/ LowCode.<br></br>
                                                Accelerate and scale digital workflow
                                                creation across the enterprise with codeless reusable
                                                flows and components.
                                            </h5>
                                            <ul>
                                                <li>
                                                    <h5>Codeless Interation to Flows with DIY FLow</h5>
                                                </li>
                                                <li>
                                                    <h5>
                                                        Create Custom Flow Cocmponent - Discoverable,
                                                        Resuabble by Anyonne
                                                    </h5>
                                                </li>
                                                <li>
                                                    <h5>Create Flows With Out of the box Nodes</h5>
                                                </li>

                                            </ul>
                                        </span>
                                    </TabPane>
                                    <TabPane tabId="iconPills6">
                                        <span>
                                            <h4>
                                                Expose your APIs via enterprise-scale API Gateways.
                                            </h4>
                                            <ul>
                                                <li>
                                                    <h5>
                                                        Empower developers to discover and consume APIs /
                                                        microservices via the Developer Portal
                                                    </h5>
                                                </li>
                                                <li>
                                                    <h5>
                                                        Rapidly expose any application or data as an API
                                                    </h5>
                                                </li>
                                                <li>
                                                    <h5>Optimize APIs to deliver maximum performance</h5>
                                                </li>
                                                <li>
                                                    <h5>
                                                        Document each API using OpenAPI (Swagger 2.0) within
                                                        the Developer Portal
                                                    </h5>
                                                </li>
                                                <li>
                                                    <h5>
                                                        Enable API publisher to brand and customize their
                                                        Developer Portal via the Portal Manager
                                                    </h5>
                                                </li>
                                            </ul>
                                        </span>
                                    </TabPane>
                                </TabContent>
                            </Container>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default Tabs;
